<template>
    <div class="main-container">
        <div class="primary-container">
            <div class="title-wrapper">
                <h2> RESTABLECER CONTRASEÑA </h2>
            </div>
            <div class="info-text-wrapper">
                <p class="text-style">Introduzca su correo electrónico en el campo de texto inferior y reciba un enlace para establecer la nueva contraseña.</p>
            </div>
            <div class="input-field-wrapper">
                <div class="input-wrapper">
                    <span class="material-symbols-outlined">mail</span>
                    <input id="email-input" name="user_email" class="email-style" type="email" placeholder="p.ej. xyz@gmail.com">
                </div>
            </div>
        </div>
        <div class="secondary-container">
            <div class="btn-wrapper">
                <button @click="_retrieve_password_send_email" class="btn-style">
                    Enviar Correo
                </button>
            </div>
        </div>
        <div class="back-btn-wrapper">
            <a href="/user/login"> 
                <span class="material-symbols-outlined">chevron_left</span>
                Volver
            </a>
        </div>

        <div v-if="registration_success" class="success-registration-container">
            <p class="success-registration-text">
                El envío se ha realizado con éxito. Por favor, revise la bandeja de entrada de su correo electrónico y siga los pasos indicados.
            </p>
        </div>
    </div>
</template>

<script>

import APICaller from "@/components/codeComponents/APICaller.js";

export default{
    mixins: [APICaller],
    name: 'Retrieve Password',
    components: {
        
    },
    data(){
        return{
            registration_success: false,
        }
    },
    mounted(){

    },
    computed: {

    },
    created(){

        var isMenuSet = setTimeout(() => {
            if(document.getElementById('menu-container') != undefined){
                this._removePrimaryMenu();
                clearInterval(isMenuSet);
            }
        }, 50);

    },
    methods: {
        _retrieve_password_send_email(){
            var user_email = document.getElementById("email-input").value;
            var retrieve_pass_url = this.$detectEnvironment() + '/app/change-password';

            if(user_email == ""){
                this.$vueOnToast.pop('error', 'Por favor, introduzca un correo electrónico.');
            } else{
                let url = '/api/v1/retrieve-password';
                const success = response => {
                    if(response.data.response.success) {
                        this.$vueOnToast.pop('success', 'Se ha enviado el correo exitosamente. Revise la bandeja de entrada y siga los pasos para establecer la nueva contraseña.');
                        document.getElementById("email-input").value = "";
                        this.registration_success = true;
                    }
                    else {
                        this.$vueOnToast.pop('error', response.data.response.info);
                    }
                }
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('352', 'Error al enviar el correo');
                const params = new URLSearchParams;
                params.append('user_email', user_email);
                params.append('retrieve_pass_url', retrieve_pass_url);

                this._postAPICall(url, params, successHandler, failureHandler);
            }

            return true;
        },

        _removePrimaryMenu(){
            document.getElementById('menu-container').style.display = "none";
        }
    },
}
</script>

<style scoped>

.main-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-field-wrapper{
    text-align: center;
    margin-top: 10px;
}

.info-text-wrapper{
    border-left: 5px solid #00add9;
    padding: 10px;
    background-color: #f0f0f0;
    width: 300px;
}

.text-style{
    font-size: 13px;
}

.secondary-container{
    margin-top: 10px;
}

.btn-style{
    background-color: #00add9;
    border-radius: 20px;
    border: none;
    height: 40px;
    width: 180px;
    text-align: center;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.email-style{
    width: 80%;
    border: none !important;
    border-bottom: 1px solid #242632 !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 10px 30px 10px 5px !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #242632 !important; 
}

.email-style:focus{
    outline: none;
}

.input-wrapper{
    display: flex;
    align-items: center;
}

.success-registration-text{
    color: green;
    font-size: 14px;
}

.success-registration-text a{
    color: #00add9 !important;
}

.back-btn-wrapper{
    margin-top: 20px;
    font-size: 15px;
    color: #00add9;
}

.back-btn-wrapper a{
    display: flex;
}

</style>
