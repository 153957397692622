import { render, staticRenderFns } from "./RetrievePassword.vue?vue&type=template&id=fbaa3342&scoped=true&"
import script from "./RetrievePassword.vue?vue&type=script&lang=js&"
export * from "./RetrievePassword.vue?vue&type=script&lang=js&"
import style0 from "./RetrievePassword.vue?vue&type=style&index=0&id=fbaa3342&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbaa3342",
  null
  
)

export default component.exports